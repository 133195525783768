import React, { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { getUserlistteam, getUserlistSearch } from "../Action/GetAction";
import { useToasts } from 'react-toast-notifications';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { Link, useHistory } from "react-router-dom";
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-overlay-loader/styles.css';
import { BASE_IMAGE_URL } from "../Utils/ApiServices";
import { ExportToExcel } from '../tools/ExportToExcel';
import { format } from 'fecha';
const UserTeam = (props) => {
    const [toggleSearchBar, setToggleSearchBar] = useState(true);
    const [productList, setProductList] = useState([]);
    const [productList2, setProductList2] = useState([]);
    const [countProduct, setCountProduct] = useState(0);
    const [paginationIndex, setPageIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [searchsub, setSearchsub] = useState('');
    const [searchst, setSearchst] = useState('');
    const [excelData, setExcelData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [searchPage, setSearchPage] = useState();

    const getListData = async (page, limit) => {
        await getUserlistteam(page, limit, false).then(async response => {
            {
                if (response.status) {
                    setLoader(false);
                    setProductList(response.data);
                    setProductList2(response.data)
                    setCountProduct(response.data.length);
                    setPageIndex(Math.ceil(response.data.length / limit));
                }
            }
        })
    }

    const handlePageClick = async (e) => {
        const selectedPage = parseInt(e.selected + 1);
        setLoader(true);
        if (search) {
            await handleSerach(selectedPage, 10, search);
        } else {
            await getListData(selectedPage, 10);
        }

    };
    const handleSubmitSearch = async (e) => {
        e.preventDefault();
        const { search } = e.target.elements;
        setSearchPage(0);
        handleSerach(1, 10, search.value)
    }


    const search_filter = (e) => {
        e.preventDefault();
        setLoader(true);
        setProductList([])
        let filterBySearch = productList2
        if (search != "") {
            filterBySearch = filterBySearch.filter((item) => {
                if ((item?.user?.firstName + " " + item?.user?.lastName + " " + item?.user?.email + " " + item?.planDetails?.planName + " " + item?.paymentId + " " + item?.cancelsubscription + " " + item?.billingType).toLowerCase()
                    .includes(search.toLowerCase())) { return item; }
            })
        }
        if (searchsub != "") {
            filterBySearch = filterBySearch.filter((item) => {
                if(JSON.parse(searchsub)){
                    if ((item?.cancelsubscription == JSON.parse(searchsub))) { return item; }
                }else{
                    if ((item?.cancelsubscription == JSON.parse(searchsub) && item?.paymentStatus == "paid" )) { return item; }
                }
               
            })
        }
        if (searchst != "") {
            filterBySearch = filterBySearch.filter((item) => {
                if ((item?.billingType).toLowerCase()
                    .includes(searchst.toLowerCase())) { return item; }
            })
        }
        setCountProduct(filterBySearch.length);
        setTimeout(() => {
            setProductList(filterBySearch)
            setLoader(false);
        }, 1000);
    }


    const handleSerach = async (page, limit, value) => {
        setLoader(true)
        try {
            const response = await getUserlistteam(page, limit, value.toLowerCase());
            if (response && response.status) {
                setLoader(false);
                setProductList(response.data);
                setCountProduct(response.data.length);
                setPageIndex(Math.ceil(response.total / limit));
            }
        } catch (error) {
            setLoader(false);
        }
    }


    const dataAllUser = async (page, lim) => {
        setExcelData([]);
        await getUserlistteam(page, lim, true).then(async response => {
            {
                if (response.success) {
                    let newData = (response.data)?.map((items, index) => ({
                        S_No: index + 1,
                        userId: items?._id ? items?._id : '',
                        FirstName: items?.firstName ? items?.firstName : '',
                        LastName: items?.lastName ? items?.lastName : '',
                        Email: items?.email ? items?.email : '',
                        Profile: items?.profilePicture ? BASE_IMAGE_URL + items?.profilePicture : '',
                        Provider: items?.provider ? items?.provider : '',
                        Verified: items?.verified ? items?.verified : false,
                        Active: items?.active ? items?.active : false,
                        Dob: items?.dob ? items?.dob : '',
                        Wallet: items?.wallet ? items?.wallet : 0,
                        Role: items?.role ? items?.role : '',
                        NewsletterSubscribe: items?.newsletterSubscribe ? items?.newsletterSubscribe : false,
                        TermsAndCondition: items?.termsAndCondition ? items?.termsAndCondition : false,
                        CouponUsed: items?.couponUsed?.length ? ((items?.couponUsed).map((n) => n?.name)).join(', ') : '',
                        ActivePlan: items?.userActiveBundleId ? (items?.userActiveBundleId?.bundleId) : '',
                        CardCreated: items?.createdCards ? items?.createdCards : 0,
                        CardReceived: items?.receivedCards ? items?.receivedCards : 0,
                        CardSigned: items?.signedCards ? items?.signedCards : 0,
                    }))

                    setExcelData(newData)
                }
            }
        })
    }
    var divisor = (countProduct / 10) >> 0;
    var remainder = countProduct % 10;
    useEffect(async () => {
        await getListData(1, 10);
        await dataAllUser(1, 10);
    }, [])
    return (
        <>
            <div className="nk-content ">
                <div className="container-fluid">
                    <div className="nk-content-inner">
                        <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                    <div className="nk-block-head-content">
                                        <h3 className="nk-block-title page-title">User Team</h3>
                                        <div className="nk-block-des text-soft">
                                            <p>You have total {countProduct === 1 ? countProduct + ' user' : countProduct + ' users'}.</p>
                                        </div>
                                    </div>
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                        {/* <div className="toggle-expand-content" data-content="pageMenu">
                                            <ul className="nk-block-tools g-3">
                                                <li>
                                                    {
                                                        excelData?.length ?
                                                            <ExportToExcel apiData={excelData} fileName={"greetpool-user-list-page" + Date.now()} />
                                                            : null
                                                    }
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="nk-block">
                                <div className="card card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        <div className="card-inner position-relative card-tools-toggle">
                                            <div className={toggleSearchBar ? 'card-search search-wrap active' : 'card-search search-wrap active'} data-search="search">
                                                <div className="card-body">

                                                    <form className="search-content" onSubmit={(e) => { search_filter(e) }} style={{ display: "flex", justifyContent: "start" }}>
                                                        <input type="text" onChange={(e) => setSearch(e.target.value)} name='search' id='search' className="form-control border-transparent form-focus-none" placeholder="Search by user name/email..." style={{ paddingLeft: '0px', textTransform: 'none', width: "calc(100% - 400px)" }} />
                                                        <select className="btn btn-white btn-outline-light" style={{
                                                            width: '150px', height: '100%', outline: 'none',
                                                            border: 'solid rgb(82, 100, 132) 1px', backgroundColor: 'rgb(82, 100, 132)',
                                                            color: '#fff', margin: '0px', marginTop: '0px', marginLeft: '10px'
                                                        }} onChange={(e) => setSearchsub(e.target.value)}>
                                                            <option value=''>Filter By Status</option>
                                                            <option value={false}>Active</option>
                                                            <option value={true}>Inactive</option>
                                                        </select>

                                                        <select className="btn btn-white btn-outline-light" style={{
                                                            width: '180px', height: '100%', outline: 'none',
                                                            border: 'solid rgb(82, 100, 132) 1px', backgroundColor: 'rgb(82, 100, 132)',
                                                            color: '#fff', margin: '0px', marginTop: '0px', marginLeft: '10px'
                                                        }} onChange={(e) => setSearchst(e.target.value)}>
                                                            <option value=''>Filter By Subscription</option>
                                                            <option value='monthly'>Monthly</option>
                                                            <option value='annually'>Annually</option>
                                                        </select>

                                                        <button className="search-submit btn btn-icon"><em className="icon ni ni-search"></em></button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-inner p-0">
                                            <div className="nk-tb-list nk-tb-ulist">
                                                <div className="nk-tb-item nk-tb-head">
                                                    <div className="nk-tb-col nk-tb-col-check" style={{ width: '0px', padding: '10px' }}>
                                                    </div>
                                                    <div className="nk-tb-col"><span className="sub-text">Name</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Email Id</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Status</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Payment Status</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Plan Name</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Subscription</span></div>
                                                    {/* <div className="nk-tb-col tb-col-md"><span className="sub-text">isSocial</span></div> */}
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Created</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Last Active</span></div>
                                                    <div className="nk-tb-col nk-tb-col-tools text-right"><span className="sub-text">Payment Id</span></div>
                                                </div>
                                                {
                                                    loader ?
                                                        <LoadingOverlay className='loaderDiv'>
                                                            <Loader loading={loader} />
                                                        </LoadingOverlay>
                                                        : null
                                                }
                                                {
                                                    productList?.length > 0 ?
                                                        productList?.map((data, index) =>
                                                            <div className="nk-tb-item" key={index}>
                                                                <div className="nk-tb-col nk-tb-col-check">
                                                                    <div className="custom-control custom-control-sm custom-checkbox notext" style={{ paddingLeft: '0px', height: '45px' }}>
                                                                        <div className="user-avatar bg-success-dim">
                                                                            {
                                                                                data?.profilePicture ?
                                                                                    <img src={BASE_IMAGE_URL + data?.profilePicture} />
                                                                                    :
                                                                                    <span style={{ textTransform: 'uppercase' }}>{data?.firstName && data?.lastName ? data?.firstName[0] + data?.lastName[0] : 'FL'}</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="nk-tb-col">
                                                                    <div className="user-info">
                                                                        <span className="tb-lead" style={{ textTransform: 'capitalize' }}><Link to={'user-details?id=' + data?.user?._id}  >{data?.user?.firstName + ' ' + (data?.user?.lastName != undefined ? data?.user?.lastName : '')} </Link> </span>
                                                                    </div>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.user?.email}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.paymentStatus == "paid" ? data?.cancelsubscription ? "Inactive" : "Active" : "Inactive"}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.paymentStatus}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.planDetails?.planName ? data?.planDetails?.planName : '----'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.billingType ? data?.billingType : '----'}</span>
                                                                </div>
                                                                {/* <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.provider ? data?.provider === 'google' || data?.provider === 'facebook' ? 'Yes' : 'No' : 'No'}<small> ({(data?.provider).toUpperCase()})</small></span>
                                                                </div> */}
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data.createdAt ? format(new Date((data?.createdAt)), 'Do MMM YYYY') : '---'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data.updatedAt ? format(new Date((data?.updatedAt)), 'Do MMM YYYY') : '---'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.paymentId ? data?.paymentId : '---'}</span>
                                                                </div>
                                                                {/* <div className="nk-tb-col nk-tb-col-tools">
                                                                    <ul className="nk-tb-actions gx-1">
                                                                        <li>
                                                                            <div className="drodown">
                                                                                <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                                    <ul className="link-list-opt no-bdr">
                                                                                        <li><Link to={'/team-details?id=' + data?.user?._id}><em className="icon ni ni-focus"></em><span>View Team</span></Link></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div> */}
                                                            </div>
                                                        )
                                                        :
                                                        <LoadingOverlay className='loaderDiv'>
                                                            <Loader loading={loader} />
                                                            <h1 style={{ width: '100%', textAlign: 'center', fontSize: '16px', lineHeight: '61px', color: '#eb5757', fontWeight: '600' }}>*Not Found</h1>
                                                        </LoadingOverlay>
                                                }
                                            </div>
                                        </div>
                                        {/* {
                                            paginationIndex ?
                                                <div className="card-inner">
                                                    <ReactPaginate
                                                        forcePage={searchPage}
                                                        previousLabel={"Prev"}
                                                        nextLabel={"Next"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={remainder >= 1 ? divisor + 1 : divisor}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={(e) => handlePageClick(e)}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"} />
                                                </div>
                                                : null
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserTeam;